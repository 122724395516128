@import 'index-mixins';
@import 'index-variables';

$activeColor: #ffd600;

.wrapper {
  position: relative;
  padding: 136px 0 108px;

  box-sizing: border-box;
  min-height: 384px;

  &--bus,
  &--activities {
    //padding: 128px 0 32px;

    @include maxMobile {
      min-height: 440px;
      padding: 0;
    }
  }

  &--withTitle {
    padding: 128px 0 28px;

    .indexButtonWrapper {
      display: none;
      text-align: center;
      .indexButton {
        border: none;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    @include maxTablet {
      padding-top: 64px;
    }
  }

  @include maxTablet {
    height: auto;
  }

  @include maxMobile {
    padding: 0;
    margin: 0;

    height: auto;

    &--withTitle {
      min-height: 268px;
      padding: 48px 0 0;
      .indexButtonWrapper {
        display: block;
      }
      form {
        display: none;
      }
    }
  }
}

.container {
  position: relative;

  /*
  Почему-то в сторибуке в FC с RailwaySearchForm все ок, а тут не ок.
  Ломается высота и форма растягивается. Подозреваю, что какое-то обновление peerDependency у RailwaySearchForm
  сломало поведение.
  TODO: пофиксить RailwaySearchForm и убрать этот костылик
   */
  & div[data-locator='Dropdown'] {
    height: auto;
  }

  @include maxMobile {
    //  width: 100%;
    min-height: 204px;
    padding: 16px 0;
    box-sizing: border-box;
  }
}

.container--activities {
  @include maxMobile {
    width: 100%;
    padding: 16px;
    // нужно, чтобы последние поиски не обрезались
  }
}

.nav {
  display: flex;
  padding: 0 16px;
  max-width: calc(100% - var(--search-form-submit-button-desktop-width));
  box-sizing: border-box;

  white-space: nowrap;
  color: blue;

  @include maxTablet {
    display: none;
  }
}

.navLink {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: $colorWhite;
  text-transform: uppercase;
  padding: 0 8px 20px;
  opacity: 1;
  transition: opacity $durationMedium1;

  text-decoration: none;

  @include maxTablet {
    font-size: 12px;
    padding: 0 6px 16px;
  }

  // Safari 11.1+
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }

  &:hover:not(.navLinkActive) {
    opacity: 0.6;
  }
}

.navLinkActive {
  color: $activeColor;
  cursor: default;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 8px;
    margin-top: 12px;
    background-color: $activeColor;
  }
}

.coverWrapper {
  position: absolute;
  inset: 0;
  background: $colorGreyMedium;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include maxTablet {
    min-height: 100%;
  }
}

.coverFilter::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(rgba(48, 79, 109, 0.5), rgba(48, 79, 109, 0.5));
}

.wrapper--withTitle .cover:not(.coverFilter) {
  &:after {
    display: none;
  }
}

.badge {
  margin-left: 4px;
}

.hotelsBadge {
  margin: -2px 0 0 4px;
}

.caption {
  display: none;

  @include font-title-2;
  font-weight: 500;
  text-align: center;
  color: $colorWhite;

  @include maxMobile {
    display: block;
    position: relative;
  }

  &:not(:empty) {
    margin: 24px 0;
    margin-top: 8px;
  }
}

.percentsBadge {
  position: absolute;
  right: 0;
  top: -14px;

  padding: 4px 8px;
  color: $colorWhite;
  background: $colorGreenWildCashback;

  border-radius: 22px;
  transform: rotate(9deg);

  font-family: 'Montserrat', sans-serif;
  @include font-small;
}

.form-loader {
  padding: 16px;
}

.form {
  width: 100% !important; //Временно, надо выпилить из форм width
  margin: 0;

  @include maxTablet {
    padding: 0;
  }
}

.titleContainer {
  text-align: center;
  margin: 0 0 48px;
  @include maxMobile {
    margin-bottom: 32px;
  }
}

.title {
  @include font-title-2;
  color: $colorWhite;
  margin: 0 0 12px;
}

.subtitle {
  @include font-base;

  color: rgba(255, 255, 255, 0.6);
}

form[class*='HotelsSearchForm'] {
  margin-block-end: 0;
}

.navLinkSeparated {
  margin-left: auto;
}
