@import 'index-mixins';
@import 'index-variables';

.wide-container {
  width: 100%;
}

.container {
  position: absolute;
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 16px 0;
  color: #fff;
  font-weight: 300;
  z-index: 1;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - #{$gutterWidth} * 4);
}

.logoWrapper {
  padding-right: 8px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.wildCashbackBadge {
  position: relative;
  max-width: 190px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 4px 6px 28px;
  border-radius: 16px;

  color: $colorWhite;
  background: rgba($colorWhiteRGB, 0.2);
  backdrop-filter: blur(10px);

  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  line-height: 120%;
  font-weight: 700;
  text-decoration: none;
}

.wildCashbackBadgeDisabled {
  pointer-events: none;
  cursor: default;
}

.wildCashbackHanger {
  position: absolute;
  left: 0;
  transform: rotate(25deg);
}

.percentsBadge {
  padding: 8px 10px;
  color: $colorWhite;
  background: $colorGreenWildCashback;

  border-radius: 22px;
  transform: rotate(5deg);

  font-family: 'Montserrat', sans-serif;
  @include font-small;
}

.nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoSeparator {
  width: 1px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 0 0 8px;
}

.logo {
  margin: 0 24px 0 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &--partner {
    padding: 0 0 0 24px;
    img {
      max-height: 48px;
    }
  }

  &-image {
    max-height: 48px;
  }

  &-wrapper {
    display: flex;
  }
}

.auth {
  position: relative;
}

.mobileAppLink {
  opacity: 1;
  margin: -4px 4px 0 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:last-of-type {
    margin: 0 20px 0 0;
  }
}
